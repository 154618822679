import React, { forwardRef } from "react";
import List from "./list";

import Benefit1 from "../../images/benefits/benefit1.png";
import Benefit2 from "../../images/benefits/benefit2.png";
import Benefit3 from "../../images/benefits/benefit3.png";
import Benefit4 from "../../images/benefits/benefit4.png";

import { listItems1, listItems2, listItems3, listItems4 } from "./listData";

const Index = forwardRef(({ handleOpenPopup, whySubId, beneId }, refs) => {
  const { whySubRef, beneRef } = refs;

  return (
    <>
      <div className="xl:pt-20 xl:pb-32 pt-10 pb-20">
        <div className="px-4 sm:px-10 xl:px-0">
          <div id={whySubId} ref={whySubRef} className="scroll-mt-navbar-sm md:scroll-mt-navbar-md lg:scroll-mt-navbar-lg"></div>
          <div className="flex xl:flex-row flex-col">
            <div className="w-full xl:w-[50%] mt-0 sm:mt-20 flex justify-center lg:block">
              <img src={Benefit1} alt="Benefit1" className="lg:w-[95%] w-[100%] h-auto object-contain" />
            </div>
            <div className="w-full xl:w-[50%] xl:pl-16 pl-0 pr-0 mt-9 sm:mt-14 xl:mt-0">
              <List
                listItems={listItems1}
                btnText="WHY SUBSCRIPTIONS"
                text="A loyalty program with no downside"
                btnTextRed="Book a Demo"
                handleOpenPopup={handleOpenPopup}
              />
            </div>
          </div>
          <div className="flex mt-16 xl:flex-row flex-col-reverse">
            <div className="w-full xl:w-[50%] pl-0 xl:pr-16 pr-0 mt-9 sm:mt-14 xl:mt-0">
              <List
                listItems={listItems2}
                btnText="WHY PIZZABOX"
                text="Our Subscription Platform has you covered."
                text2="We provide:"
                btnTextRed="Let’s Get Started"
                handleOpenPopup={handleOpenPopup}
              />
            </div>
            <div className="w-full xl:w-[50%] mt-0 sm:mt-20 xl:pl-14 pl-0 flex justify-center lg:block">
              <img src={Benefit2} alt="Benefit1" className="lg:w-[95%] w-[100%] h-auto object-contain" />
            </div>
          </div>
          <div className="flex mt-16 xl:flex-row flex-col">
            <div id={beneId} ref={beneRef} className="scroll-mt-navbar-sm md:scroll-mt-navbar-md lg:scroll-mt-navbar-lg"></div>
            <div className="w-full xl:w-[50%] mt-0 sm:mt-20 flex justify-center lg:block">
              <img src={Benefit3} alt="Benefit3" className="lg:w-[95%] w-[100%] h-auto object-contain" />
            </div>
            <div className="w-full xl:w-[50%] xl:pl-16 pl-0 pr-0 mt-9 sm:mt-14 xl:mt-0">
              <List
                listItems={listItems3}
                btnText="BENEFITS"
                text="Pizza Subscriptions are all about profits"
                btnTextRed="Book a Demo"
                handleOpenPopup={handleOpenPopup}
              />
            </div>
          </div>
          <div className="flex mt-16 xl:flex-row flex-col-reverse">
            <div className="w-full xl:w-[50%] pl-0 xl:pr-16 pr-0 mt-9 sm:mt-14 xl:mt-0">
              <List
                listItems={listItems4}
                btnText="GOOD PROBLEMS"
                text="Success comes with challenges"
                btnTextRed="Let’s Get Started"
                handleOpenPopup={handleOpenPopup}
              />
            </div>
            <div className="w-full xl:w-[50%] mt-0 sm:mt-20 xl:pl-14 xl-0 flex justify-center lg:block">
              <img src={Benefit4} alt="Benefit4" className="lg:w-[95%] w-[100%] h-auto object-contain" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Index;
