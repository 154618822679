import React from "react";

import Dollar from "../../images/pizza-subscription/dollar.png";
import DollarStack from "../../images/pizza-subscription/dollar-stack.png";
import DollarUp from "../../images/pizza-subscription/dollar-up.png";
import PizzaLogo from "../../images/pizzaLogo.png";

import IframePopupComponentCaseStudy from "../CaseStudyIframe/index";

const Index = React.forwardRef(({ id, handleOpenPopup }, ref) => {

  const [openCaseStudyPopup, setOpenCaseStudyPopup] = React.useState(false);

  const handleOpenSubscriptionPage = () => {
    window.open("https://pizzapass.flintridgepizzakitchen.com/subscribe", "_blank")
  };

  const handleOpenCaseStudy = () => {
    setOpenCaseStudyPopup(true);
  };

  const handleCloseCaseStudy = () => {
    setOpenCaseStudyPopup(false);
  };

  return (
    <>
      <div className="xl:pt-32 xl:pb-20 sm:pt-20 sm:pb-32 pt-8 pb-12">
        <h2 className="font-degularDisplay text-[50px] sm:text-[82px] lg:text-[110px] leading-[3rem] sm:leading-[6rem] lg:leading-[8rem] xl:leading-[0rem] xl:text-[82px] font-semibold text-center text-[#DA0000]">
          What is a Pizza Subscription?
        </h2>
        <div className="xl:mt-20 mt-10">
          <p className="font-sfPro text-center text-[20px] sm:text-[35px] lg:text-[38px] xl:text-[29px] text-[#000]">
            <span className="text-[#740C00] font-semibold">Pizza Subscription is a formula for success.</span> Customers purchase a subscription.  You generate revenue immediately. Customers come back for more every week. You generate more income by upselling. <span className="text-[#740C00] font-semibold">It’s a Win-Win!</span>
          </p>
          <p className="font-sfPro text-center text-[20px] sm:text-[35px] lg:text-[38px] xl:text-[29px] text-[#000] mt-8">
          Imagine having a steady stream of guaranteed income rolling in, each and every week for a year. <br className="hidden xl:block"/> Now it’s possible with the Pizza Subscription program powered by <img src={PizzaLogo} alt="PizzaLogo" className="inline w-36 sm:w-48 ml-2 mt-[-4px]"/>
          </p>
        </div>
        <div className="mt-12 w-full flex justify-center">
          <button className="font-sfPro font-semibold bg-[#FBCD33] text-black py-2 px-0.5 rounded-full xl:text-[32px] text-[18px] sm:text-[38px] lg:text-[40px] xl:w-[550px] w-[90%] mx-auto text-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleOpenSubscriptionPage}>
          Check out a live Pizza Subscription
          </button>
        </div>
        <div id={id} ref={ref} className="scroll-mt-navbar-sm md:scroll-mt-navbar-md lg:scroll-mt-navbar-lg"></div>
        <div className="mt-16">
          <p className="font-degularDisplay text-center text-[38px] sm:text-[70px] lg:text-[80px] xl:text-[62px] text-black mt-5 font-semibold">
            Here's how it works:
          </p>
          <div className="mt-8 flex xl:flex-row flex-col items-center justify-center xl:gap-40 gap-20">
            <div className="flex flex-col gap-4 items-center">
              <img src={Dollar} alt="Dollar" className="transition-transform duration-300 ease-in-out hover:bounce-scale"/>
              <p className="font-sfPro text-center text-[22px] sm:text-[32px] lg:text-[40px] xl:text-[25px] 2xl:text-[28px] text-[#000]">
                Customers pay upfront for a <br />year of weekly pizza
              </p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <img src={DollarStack} alt="DollarStack" className="transition-transform duration-300 ease-in-out hover:bounce-scale"/>
              <p className="font-sfPro text-center text-[22px] sm:text-[32px] lg:text-[40px] xl:text-[25px] 2xl:text-[28px] text-[#000]">
                You get an upfront cash injection <br /> without interest or loans
              </p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <img src={DollarUp} alt="DollarUp" className="transition-transform duration-300 ease-in-out hover:bounce-scale"/>
              <p className="font-sfPro text-center text-[22px] sm:text-[32px] lg:text-[40px] xl:text-[25px] 2xl:text-[28px] text-[#000]">
                Regulars become even more <br />loyal and spend more
              </p>
            </div>
          </div>
        </div>
        <div className="mt-16 w-full flex justify-center">
          <button className="font-sfPro font-semibold bg-[#FBCD33] text-black py-2 px-0.5 rounded-full xl:text-[32px] text-[18px] sm:text-[38px] lg:text-[40px] xl:w-[550px] w-[90%] mx-auto text-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleOpenCaseStudy}>
            Download a Pizzeria Case Study
          </button>
        </div>
      </div>
      <IframePopupComponentCaseStudy openIframePopup={openCaseStudyPopup} onClose={handleCloseCaseStudy} />
    </>
  );
});

export default Index;
