import React, { useEffect } from "react";


import Line from "../../images/sline-2.png";
import Smile from "../../images/smile.png";
// import commentBoxBg from "../../images/comment_box2.svg";
import commentBoxBg from "../../images/comment_box2.png";
import "./index.css";

const Index = ({ handleOpenPopup }) => {
  const style = {
    backgroundImage: `url(${commentBoxBg})`,
    imageRendering: 'optimizeQuality',
  };

  return (
    <>
      <div className="xl:pt-32 xl:pb-44 pt-16 pb-20">
        <div className="flex xl:flex-row flex-col 2xl:gap-6">
          <div className="w-full xl:w-[50%] px-5 xl:px-0">
            <div className="relative w-[260px] h-[260px] ip5:w-[320px] ip5:h-[320px] ip6:w-[370px] ip6:h-[380px] nx7:w-[550px] nx7:h-[550px] sm:w-[600px] sm:h-[600px] md:w-[675px] md:h-[675px] xl:w-[640px] xl:h-[660px] 2xl:w-[780px] 2xl:h-[780px] bg-contain	bg-no-repeat bg-center mx-auto xl:mt-[-20px] 2xl:mt-[-30px]" style={style}>
              <h2 className="text-3xl ip5:text-4xl ip6:text-5xl nx7:text-7xl sm:text-7xl md:text-7xl xl:text-7xl 2xl:text-8xl font-degularDisplay font-semibold pl-10 nx7:pl-12 sm:pl-16 xl:pl-20 2xl:pl-24 pt-8 ip6:pt-12 nx7:pt-14 sm:pt-16 xl:pt-20 2xl:pt-20 text-black">
                Get Your Pizza <br /> Once a Week <br /> For 52 Weeks <br /> For {" "}
                <span className="relative">
                  Just $197
                  <img
                    src={Line}
                    alt="Line"
                    className="absolute w-full left-0 bottom-[-8px]"
                  />
                </span>{" "}
              </h2>
              <p className="text-[14px] ip6:text-[16px] nx7:text-[22px] sm:text-[26px] lg:text-[30px] md:text-[30px] xl:text-[30px] 2xl:text-[37px] leading-12 font-oswald text-[#740C00] mt-7 ip6:mt-6 nx7:mt-10 sm:mt-14 mb-3 transform-text pl-10 nx7:pl-14 sm:pl-16 xl:pl-20 2xl:pl-24">
                This simple formula gets customers to pay <br /> upfront and return every
                week for more.
              </p>
            </div>
            <div className="flex justify-center  xl:justify-start xl:ml-8">
              <button className="font-sfPro font-semibold bg-[#BE0009] text-white py-2 px-4 rounded-full text-[25px] sm:text-[40px] lg:text-[55px] xl:text-[30px] xl:w-[50%] 2xl:w-[45%] w-[70%] sm:w-[55%] text-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleOpenPopup}>
                Book a Demo
              </button>
            </div>
          </div>
          <div className="w-full xl:w-[50%] xl:pl-20 pl-0 pr-0 xl:mt-0 mt-6 ip6:mt-10">
            <div className="flex flex-col gap-5">
              <img src={Smile} alt="Smile" className="w-[100%]" />
              <p className="font-oswald text-[24px] sm:text-[50px] lg:text-[26px] xl:text-[20px] 2xl:text-[26px] font-medium text-center text-[#DA0000]">
                “If you haven't implemented a pizza subscription in your pizza
                restaurant, you risk losing your customers to those who have.”
              </p>
              <p className="font-sfPro text-[22px] xl:text-[24px] lg:text-[26px] 2xl:text-[28px] font-semibold text-[#000000] text-center lg:text-end">
                Vincent <span className="font-sfPro font-light text-[#DA0000] text-[18px] xl:text-[19px] 2xl:text-[22px] lg:text-[24px]">[Flintridge Pizza Kitchen]</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
