import React from "react";
import { useLocation } from 'react-router-dom';

import Banner from "../components/SubscriptionBanner/index";
import PizzaSubscription from "../components/PizzaSubscription/index";
import SubscriptionPortFolio from "../components/SubscriptionPortFolio/index";
import PortfolioCalculator from "../components/PortfolioCalculator/index";
import Benefits from "../components/SubscriptionBenefits/index";
import FAQ from "../components/FAQ/index";
import Footer from "../components/SubscriptionFooter/index";
import Iframe from "../components/Iframe/index";
import SubscriptionPopup from "../components/SubscriptionPopup/index";

import CaseStudyModal from "../components/ThankYouModal/CaseStudyModal";
import MerchantModal from "../components/ThankYouModal/MerchantModal";
import ReferralModal from "../components/ThankYouModal/ReferralModal";

import MerchantIframe from "../components/MerchantIframe/index";

import Logo from "../images/slogo.svg";
import MenuOpener from "../images/mobile_menu_opener.png";

const Homepage = () => {
  const [queryParams, setQueryParams] = React.useState("");
  const [queryParamsWithOutHash, setQueryParamsWithOutHash] = React.useState("");

  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const [openIframePopup, setOpenIframePopup] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openCaseStudyModal, setOpenCaseStudyModal] = React.useState(false);
  const [openMerchantModal, setOpenMerchantModal] = React.useState(false);
  const [openReferralModal, setOpenReferralModal] = React.useState(false);
  const [openMerchantIframe, setOpenMerchantIframe] = React.useState(false);

  const topRef = React.useRef(null);
  const whySubscriptionRef = React.useRef(null);
  const howItWorkRef = React.useRef(null);
  const benefitRef = React.useRef(null);
  const faqRef = React.useRef(null);

  const location = useLocation();

  React.useLayoutEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      if (hash === "#HOWITWORKS" && howItWorkRef.current) {
        // Scroll again if there was any chance that the layout has changed
        howItWorkRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#WHYSUBSCRIPTIONS" && whySubscriptionRef.current) {
        // Scroll again if there was any chance that the layout has changed
        whySubscriptionRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#BENEFITS" && benefitRef.current) {
        // Scroll again if there was any chance that the layout has changed
        benefitRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (hash === "#FAQ" && faqRef.current) {
        // Scroll again if there was any chance that the layout has changed
        faqRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const loadIframe = () => {
    // Append the script tag to the document body when the popup is open
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    // return () => {
    //   // Clean up the script when the popup is closed
    //   document.body.removeChild(script);
    // };
  };

  React.useEffect(() => {
    loadIframe();

    let iframeUrl =
      "https://api.leadconnectorhq.com/widget/booking/4dyJhjVr4Ry5TwY6RzsP";

    let iframeUrlMerchant = "https://api.leadconnectorhq.com/widget/form/UNFtd7cQWfcQTXrO3PwF";

    const hash = window.location.hash;
    const url = window.location.href;

    let params = checkParamsInUrl(url);
    let paramsWithOutHash = checkParamsInUrlWithOutHash(url);
    let paramsBookADemo = checkParamsInUrlWithOutHashBookADemo(url)
    
    let fullUrl = iframeUrl + params;
    let urlForIframe = params ? fullUrl : iframeUrl;

    let fullUrlWithOutHash = iframeUrlMerchant + paramsWithOutHash;
    let urlForIframeWithOutHash = paramsWithOutHash ? fullUrlWithOutHash : iframeUrlMerchant;

    setQueryParams(urlForIframe);
    setQueryParamsWithOutHash(urlForIframeWithOutHash);

    const pathParts = location.pathname.split('/');
    const referralModalPart = pathParts[pathParts.length - 1];

    if (!hash && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (params) {
      setOpenIframePopup(true);
    } else {
      setOpenIframePopup(false);
    }

    if (referralModalPart === "book-demo" || paramsBookADemo) {
      setOpenIframePopup(true);
    } else {
      setOpenIframePopup(false);
    }

    if (referralModalPart && referralModalPart === "casestudymodal") {
      setOpenCaseStudyModal(true);
    } else {
      setOpenCaseStudyModal(false);
    }

    if (referralModalPart && referralModalPart === "merchantmodal") {
      setOpenMerchantModal(true);
    } else {
      setOpenMerchantModal(false);
    }

    if (referralModalPart && referralModalPart === "referralmodal") {
      setOpenReferralModal(true);
    } else {
      setOpenReferralModal(false);
    }

    if (referralModalPart === "merchant-info-form" || paramsWithOutHash) {
      setOpenMerchantIframe(true);
    } else {
      setOpenMerchantIframe(false);
    }
  }, []);

  const checkParamsInUrl = (url) => {
    // const url = "https://subscription.pizzabox.ai/#BOOKADEMO?contact_id=hnwM3tVip3OmV04N4Grf&email=nv.vasilkin@gmail.com&phone=(234)+290-1466&restaurant_name=Nick+rest&restaurant_website=orderbyte.io";

    const hashIndex = url.indexOf("#");
    if (hashIndex !== -1) {
      const hashPart = url.substring(hashIndex + 1);
      const queryIndex = hashPart.indexOf("?");
      if (queryIndex !== -1) {
        return hashPart.substring(queryIndex); // This includes the leading "?"
      }
    }
    return "";
  };

  const checkParamsInUrlWithOutHash = (url) => {
    // const url = "http://localhost:3000/merchant-info-form?contact_id=hnwM3tVip3OmV04N4Grf&email=nv.vasilkin@gmail.com&phone=(234)+290-1466&restaurant_name=Nick+rest&website=1111111";

    // Define the specific path segment to look for
    const pathSegment = "/merchant-info-form";

    // Check if the URL contains the specific path segment
    if (url.includes(pathSegment)) {
      // If there's a query string, extract it
      const queryIndex = url.indexOf("?");
      if (queryIndex !== -1) {
        return url.substring(queryIndex); // This includes the leading "?"
      }
    }

    // If the path segment is not found or there is no query string, return an empty string
    return "";
  };

  const checkParamsInUrlWithOutHashBookADemo = (url) => {
    // const url = "https://subscription.pizzabox.ai/book-demo?contact_id=hnwM3tVip3OmV04N4Grf&email=nv.vasilkin@gmail.com&phone=(234)+290-1466&restaurant_name=Nick+rest&restaurant_website=orderbyte.io";

    // Define the specific path segment to look for
    const pathSegment = "/book-demo";

    // Check if the URL contains the specific path segment
    if (url.includes(pathSegment)) {
      // If there's a query string, extract it
      const queryIndex = url.indexOf("?");
      if (queryIndex !== -1) {
        return url.substring(queryIndex); // This includes the leading "?"
      }
    }

    // If the path segment is not found or there is no query string, return an empty string
    return "";
  };

  const handleScroll = (ref, id) => {
    setOpenMobileMenu(false);
    console.log("div id", id);
    let navbarHeightOffset = document.getElementById("navbar").offsetHeight;
    let navbarHeight = navbarHeightOffset + 800;
    let element = document.getElementById(id);
    let scrollTop = element - navbarHeight;
    if (element) {
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const handleOpenIframe = () => {
    setOpenIframePopup(true);
  };

  const handleCloseIframePopup = () => {
    setOpenIframePopup(false);
  };

  const handleCloseMerchantIframe = () => {
    setOpenMerchantIframe(false);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleCloseCaseStudyModal = () => {
    setOpenCaseStudyModal(false);
  };

  const handleCloseMerchantModal = () => {
    setOpenMerchantModal(false);
  };

  const handleCloseReferralModal = () => {
    setOpenReferralModal(false);
  };

  const handleCloseeIframePopup = () => {
    setOpenIframePopup(false);
  };

  return (
    <>
      <div className="bg-[#F8F4E6]">
        <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
          <div className="px-5 md:px-8 xl:px-0">
            <div id="TOP" ref={topRef}></div>
            <div
              className="w-full bg-[#EAE4CE] fixed top-0 z-50 px-6 lg:px-0 right-0"
              id="navbar"
            >
              <div className="w-full md:container">
                <nav className="pt-8">
                  <div className="py-2 flex flex-col xl:flex-row justify-between items-start">
                    {/* Logo */}
                    <div className="text-2xl font-bold w-full xl:w-[37%] flex justify-between">
                      <img
                        src={Logo}
                        alt="Logo"
                        className="w-[70%] 2xl:w-[80%]"
                      />
                      <div className="xl:hidden">
                        <img
                          src={MenuOpener}
                          alt="menu opener"
                          className="w-12 sm:w-16 md:w-20 lg:w-28"
                          onClick={handleOpenMobileMenu}
                        />
                      </div>
                    </div>

                    {/* Menu */}
                    <div
                      className={`w-full xl:w-[63%] mt-6 xl:flex transition-all duration-300 ease-in-out ${openMobileMenu ? "" : "hidden"
                        }`}
                    >
                      <div className="w-full flex flex-col xl:flex-row bg-[#FFBD00] justify-between items-center gap-1 rounded-3xl xl:rounded-full">
                        <a
                          href="#HOWITWORKS"
                          className="relative py-3 sm:py-6 xl:py-0 pl-4 xl:pl-8 pr-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                          onClick={() =>
                            handleScroll(howItWorkRef, "HOWITWORKS")
                          }
                        >
                          HOW IT WORKS
                        </a>
                        {/* <span className="hidden xl:inline">|</span> */}
                        <a
                          href="#WHYSUBSCRIPTIONS"
                          className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                          onClick={() =>
                            handleScroll(whySubscriptionRef, "WHYSUBSCRIPTIONS")
                          }
                        >
                          WHY SUBSCRIPTIONS
                        </a>
                        {/* <span className="hidden xl:inline">|</span> */}
                        <a
                          href="#BENEFITS"
                          className="relative py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                          onClick={() => handleScroll(benefitRef, "BENEFITS")}
                        >
                          BENEFITS
                        </a>
                        {/* <span className="hidden xl:inline">|</span> */}
                        <a
                          href="#FAQ"
                          className="py-3 sm:py-6 xl:py-0 px-3 text-black-900 font-semibold text-xl sm:text-2xl xl:text-[12px] 2xl:text-[17px] text-center flex-grow dot-before"
                          onClick={() => handleScroll(faqRef, "FAQ")}
                        >
                          FAQ
                        </a>
                        <a
                          href="#BOOKADEMO"
                          onClick={handleOpenIframe}
                          className="text-white py-3 sm:py-6 xl:py-2 bg-black font-semibold px-[40px] text-[24px] sm:text-[26px] xl:text-[22px] rounded-3xl xl:rounded-full w-full xl:w-64 text-center"
                        >
                          Book a demo
                        </a>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className="w-full md:container pt-24 md:pt-48 xl:pt-28">
              <Banner handleOpenPopup={handleOpenIframe} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#EAE4CE]">
        <div className="bg-[#F8F4E6] h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
          <div className="w-full md:container px-5 xl:px-0">
            <PizzaSubscription
              id="HOWITWORKS"
              ref={howItWorkRef}
              handleOpenPopup={handleOpenIframe}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#F8F4E6]">
        <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
          <div className="w-full md:container px-5 xl:px-0">
            <SubscriptionPortFolio handleOpenPopup={handleOpenIframe} />
          </div>
        </div>
      </div>
      <div className="bg-[#EAE4CE]">
        <div className="bg-[#F8F4E6] h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]]">
          <div className="w-full md:container px-5 xl:px-0">
            <PortfolioCalculator handleOpenPopup={handleOpenIframe} />
          </div>
        </div>
      </div>
      <div className="bg-[#EAE4CE] h-auto rounded-bl-[4rem] md:rounded-bl-[8rem] xl:rounded-bl-[10rem]">
        <div className="w-full md:container">
          <Benefits
            handleOpenPopup={handleOpenIframe}
            whySubId="WHYSUBSCRIPTIONS"
            beneId="BENEFITS"
            ref={{ whySubRef: whySubscriptionRef, beneRef: benefitRef }}
          />
        </div>
      </div>
      <div className="bg-[#000]">
        <div className="bg-white h-auto rounded-br-[4rem] md:rounded-br-[8rem] xl:rounded-br-[10rem]">
          <div className="w-full md:container px-5 xl:px-0">
            {/* <div id="FAQ" ref={faqRef} className="scroll-mt-navbar-sm md:scroll-mt-navbar-md lg:scroll-mt-navbar-lg"></div> */}
            <FAQ id="FAQ" ref={faqRef} />
          </div>
        </div>
      </div>
      <div className="bg-[#000] h-auto">
        <div className="w-full md:container">
          <Footer />
        </div>
      </div>
      <Iframe
        openIframePopup={openIframePopup}
        onClose={handleCloseIframePopup}
        queryParams={queryParams}
      />
      <SubscriptionPopup open={openPopup} onClose={handleClosePopup} />
      <CaseStudyModal
        open={openCaseStudyModal}
        onClose={handleCloseCaseStudyModal}
      />
      <MerchantModal
        open={openMerchantModal}
        onClose={handleCloseMerchantModal}
      />
      <ReferralModal
        open={openReferralModal}
        onClose={handleCloseReferralModal}
      />
      <MerchantIframe
        openIframePopup={openMerchantIframe}
        onClose={handleCloseMerchantIframe}
        queryParams={queryParamsWithOutHash}
      />
    </>
  );
};

export default Homepage;
