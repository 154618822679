import React from "react";
import { Slider } from "antd";
import useWindowResize from "../../hooks/common/useWindowResize";

import Netflix from "../../images/pizza-subscription/netflix.png";
import Spotify from "../../images/pizza-subscription/spotify.png";
import Starbucks from "../../images/pizza-subscription/starbucks.png";
import Home from "../../images/pizza-subscription/home.png";
import Line4 from "../../images/sline-4.png";

const Index = ({ handleOpenPopup }) => {
  const { screenWidth, screenHeight } = useWindowResize();
  const [costPerPizza, setCostPerPizza] = React.useState(3.87);
  const [subscriptionSold, setSubscriptionSold] = React.useState(533);
  const [upSellPerOrder, setUpSellPerOrder] = React.useState(9);
  const [pizzaSoldPerYear, setPizzaSoldPerYear] = React.useState(0);
  const [moneyRaised, setMoneyRaised] = React.useState(0);
  const [upSellPotential, setUpSellPotential] = React.useState(0);
  const [potentialGrossSale, setPotentialGrossSale] = React.useState(0);

  const formatNumberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const onChangeCostPerPizza = (newValue) => {
    console.log(newValue);
    setCostPerPizza(newValue);
  };

  const onChangeSubscriptionSold = (newValue) => {
    console.log(newValue);
    setSubscriptionSold(newValue);
    let pizzaSold = newValue * 52;
    let moneyRaise = 197 * newValue;
    setPizzaSoldPerYear(pizzaSold);
    setMoneyRaised(moneyRaise);
  };

  const onChangeUpSellPerOrder = (newValue) => {
    console.log(newValue);
    setUpSellPerOrder(newValue);
    let upSell = pizzaSoldPerYear * newValue;
    let grossSale = moneyRaised + upSell;
    setUpSellPotential(upSell);
    setPotentialGrossSale(grossSale);
  };

  React.useEffect(() => {
    let pizzaSold = subscriptionSold * 52;
    let moneyRaise = 197 * subscriptionSold;
    setPizzaSoldPerYear(pizzaSold);
    setMoneyRaised(moneyRaise);

    let upSell = pizzaSold * upSellPerOrder;
    let grossSale = moneyRaise + upSell;
    setUpSellPotential(upSell);
    setPotentialGrossSale(grossSale);

  }, []);


  return (
    <>
      <div className="md:pt-12 md:pb-32 pt-8 pb-16">
        <div className="mt-12 sm:mt-16 xl:mt-0 flex xl:flex-row flex-col-reverse">
          <div className="w-full xl:w-[55%] xl:pr-20 pr-0">
            <div className="mb-12">
              <h3 className="font-sfPro font-semibold text-[17px] sm:text-[30px] lg:text-[40px] xl:text-[30px] 2xl:text-[35px] text-center xl:text-left text-[#740C00]">People love Subscriptions. They subscribe to:</h3>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-8 xl:gap-1 mt-6 sm:mt-10 mb-6 sm:mb-10 place-items-center">
              <div className="flex flex-col-reverse items-center justify-center gap-2 sm:gap-4">
                <div className="text-[20px] sm:text-[28px] xl:text-[30px] 2xl:text-[32px] font-sfPro">movies</div>
                <div><img src={Netflix} alt="Netflix" className="w-20 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale" /></div>
              </div>
              <div className="flex flex-col-reverse items-center justify-center gap-2 sm:gap-4">
                <div className="text-[20px] sm:text-[28px] xl:text-[30px] 2xl:text-[32px] font-sfPro">music</div>
                <div><img src={Spotify} alt="Spotify" className="w-20 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale" /></div>
              </div>
              <div className="flex flex-col-reverse items-center justify-center gap-2 sm:gap-4">
                <div className="text-[20px] sm:text-[28px] xl:text-[30px] 2xl:text-[32px] font-sfPro">coffee</div>
                <div><img src={Starbucks} alt="Starbucks" className="w-20 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale" /></div>
              </div>
              <div className="flex flex-col-reverse items-center justify-center gap-2 sm:gap-4">
                <div className="text-[20px] sm:text-[28px] xl:text-[30px] 2xl:text-[32px] font-sfPro">meals</div>
                <div><img src={Home} alt="Home" className="w-20 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale" /></div>
              </div>
            </div>
            <div className="mt-0">
              <h3 className="font-degularDisplay font-semibold text-center xl:text-left text-[32px] sm:text-[65px] 2xl:text-[70px] leading-[2rem] sm:leading-[4.5rem]">Times have changed and <br />so has the <span className="relative">
                pizza game.
                <img
                  src={Line4}
                  alt="Line3"
                  className="absolute w-full left-0 bottom-[-8px] sm:bottom-[-14px]"
                />
              </span></h3>
            </div>
            <div className="mt-10 sm:mt-16">
              <p className="font-sfPro text-[20px] sm:text-[28px] lg:text-[26px] 2xl:text-[30px] text-[#444444] text-center xl:text-left">With a Pizza Subscription you can have a steady stream of guaranteed income rolling in each week for a year.</p>
            </div>
          </div>
          <div className="w-full xl:w-[45%] mb-12 sm:mb-20 xl:mb-0 mt-[-6rem]">
            <div className="bg-[#fff] xl:w-[95%] w-full h-auto px-8 py-12 rounded-[50px]">
              <p className="text-[21px] sm:text-[39px] lg:text-[45px] xl:text-[32px] font-oswald text-center font-semibold text-[#061737] uppercase">
                PIZZA Subscription CALCULATOR
              </p>
              <div className="flex flex-col mt-7 md:px-6 px-0">
                <div className="border-b border-[#061737] border-dashed pb-4">
                  <div className="float-left font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    Subscription price
                  </div>
                  <div className="float-right font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    $197.00
                  </div>
                </div>
                <div className="border-b border-[#061737] border-dashed pb-4 pt-3">
                  <div className="font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737] flex items-center justify-between w-full">
                    <div className="">Cost per pizza</div>
                    <div className="ml-7 w-[100px] md:w-[177px] inline-block">
                      <Slider
                        value={costPerPizza}
                        min={3}
                        max={5}
                        step={0.01}
                        onChange={onChangeCostPerPizza}
                        // tipFormatter={(value) => `${value} orders`}
                        tooltip={{
                          formatter: null,
                        }}
                      />
                    </div>
                    <div className="xl:w-[64px] lg:w-[92px]">
                      ${costPerPizza.toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="border-b border-[#061737] border-dashed pb-4 pt-3">
                  <div className="font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737] flex items-center justify-between w-full">
                    <div className="">Subscriptions sold</div>
                    <div className="ml-4 w-[100px] md:w-[177px] inline-block">
                      <Slider
                        value={subscriptionSold}
                        min={0}
                        max={1000}
                        onChange={onChangeSubscriptionSold}
                        // tipFormatter={(value) => `${value} orders`}
                        tooltip={{
                          formatter: null,
                        }}
                      />
                    </div>
                    <div className="xl:w-[64px] lg:w-[92px] text-right">
                      {subscriptionSold}
                    </div>
                  </div>
                </div>
                <div className="border-b border-[#061737] border-dashed pb-4 pt-3">
                  <div className="float-left font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    Pizzas sold per year
                  </div>
                  <div className="float-right font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    {formatNumberWithCommas(pizzaSoldPerYear)}
                  </div>
                </div>
                <div className="border-b border-[#061737] border-dashed pb-4 pt-3">
                  <div className="float-left font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    Money raised
                  </div>
                  <div className="float-right font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    ${formatNumberWithCommas(moneyRaised)}
                  </div>
                </div>
                <div className="border-b border-[#061737] border-dashed pb-4 pt-3">
                  <div className="font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737] flex items-center justify-between w-full">
                    <div className="">Upsell per order</div>
                    <div className="ml-7 w-[100px] md:w-[177px] inline-block">
                      <Slider
                        value={upSellPerOrder}
                        min={0}
                        max={20}
                        onChange={onChangeUpSellPerOrder}
                        // tipFormatter={(value) => `${value} orders`}
                        tooltip={{
                          formatter: null,
                        }}
                      />
                    </div>
                    <div className="xl:w-[73px] lg:w-[92px]">
                      ${upSellPerOrder.toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="border-b border-[#061737] border-dashed pb-4 pt-3">
                  <div className="float-left font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    Upsell potential
                  </div>
                  <div className="float-right font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#061737]">
                    ${formatNumberWithCommas(upSellPotential)}
                  </div>
                </div>
                <div className="pb-4 pt-3">
                  <div className="float-left font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#BE0009]">
                  Potential Gross Sales
                  </div>
                  <div className="float-right font-oswald text-[19px] sm:text-[40px] lg:text-[44px] xl:text-[26px] 2xl:text-[30px] text-[#BE0009]">
                    ${formatNumberWithCommas(potentialGrossSale)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 sm:mt-32 xl:mt-24 px-3 xl:px-0">
          <p className="text-[28px] sm:text-[35px] xl:text-[40px] font-oswald text-center font-semibold text-[#EB001B]">
            “This is the best loyalty program. Ever. <br />
            It is the one program that really makes you money with guest
            loyalty.”
          </p>
          <div className="mt-9 flex items-center justify-center">
            <p className="font-sfPro text-[20px] sm:text-[30px] font-semibold text-[#000000]">
              Andrew Simmons <br className="sm:hidden" /> <span className="font-sfPro text-[18px] sm:text-[22px] font-light text-[#DA0000]">
                Mamma Ramona’s Pizzeria
              </span>
            </p>

          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
