import React from "react"
import useWindowResize from "../../hooks/common/useWindowResize"

import BannerImg from "../../images/banner.png"
import Line from "../../images/sline.png"
import Line3 from "../../images/sline-3.png"
import PizzaSlice from "../../images/pizzaSlice2.png"
// import commentBoxBg from "../../images/comment_box.svg";
import commentBoxBg from "../../images/comment_box.png"
import "./index.css"

const Index = ({ handleOpenPopup }) => {
  const { screenWidth, screenHeight } = useWindowResize()
  const style = {
    backgroundImage: `url(${commentBoxBg})`,
    imageRendering: "optimizeQuality",
  }
  return (
    <div className="lg:mt-24 xl:mt-12 sm:mt-10 pb-14">
      <div className="py-2 flex flex-col xl:flex-row mt-0 sm:mt-10 gap-1 sm:gap-8">
        <div className="w-full xl:w-1/2 xl:flex xl:flex-col xl:gap-5 overflow-visible">
          <div
            className="relative w-[280px] h-[280px] ip5:w-[320px] ip5:h-[320px] ip6:w-[370px] ip6:h-[370px] nx7:w-[550px] nx7:h-[550px] sm:w-[600px] sm:h-[600px] md:w-[675px] md:h-[675px] xl:w-[580px] xl:h-[490px] 2xl:w-[700px] 2xl:h-[600px] bg-contain	bg-no-repeat bg-center mx-auto"
            style={style}
          >
            <h1 className="text-3xl ip5:text-4xl ip6:text-5xl nx7:text-7xl md:text-8xl xl:text-7xl 2xl:text-[88px] font-degularDisplay font-semibold pl-8 nx7:pl-12 sm:pl-16 pt-12 ip6:pt-16 nx7:pt-20 sm:pt-24 xl:pt-12 2xl:pt-16">
              Launch Your <br /> Pizza
              <img
                src={PizzaSlice}
                alt="PizzaSlice"
                className="inline w-10 sm:w-20 pl-2"
              />
              <br /> Subscription <br />
              <span className="relative">
                Today
                <img
                  src={Line3}
                  alt="Line3"
                  className="absolute w-full left-0 bottom-[-19px]"
                />
              </span>
            </h1>
            <button
              className="absolute right-8 bottom-8 ip5:right-10 ip5:bottom-10 nx7:right-20 nx7:bottom-20 md:right-24 md:bottom-20 xl:bottom-8 2xl:bottom-14 font-sfPro font-semibold bg-[#BE0009] text-white rounded-full text-center text-sm ip5:text-base ip6:text-lg nx7:text-xl md:text-2xl xl:text-xl 2xl:text-2xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 py-2 px-[15px] nx7:px-8 md:px-10 xl:px-8 sm:py-3 md:py-5 xl:py-3"
              onClick={handleOpenPopup}
            >
              Let’s Get Started
            </button>
          </div>
        </div>
        <div className="w-full xl:w-1/2 mt-0 sm:mt-12">
          <div className="flex flex-col gap-2 justify-center items-center">
            <img src={BannerImg} alt="BannerImg" className="w-[90%]" />
            <div className="mt-0">
              <p className="text-[26px] sm:text-[40px] lg:text-[50px] xl:text-[20px] 2xl:text-[24px] font-sfPro font-semibold text-[#0A0908] mb-3 text-center xl:text-left">
                “A{" "}
                <span className="relative">
                  must-have
                  <img
                    src={Line}
                    alt="Line"
                    className="absolute w-full left-0 bottom-[-6px]"
                  />
                </span>{" "}
                loyalty program for every pizzeria.”
              </p>
              {screenWidth < 1025 ? (
                <p className="text-[22px] leading-[26px] sm:leading-[2rem] sm:text-[38px] lg:text-[42px] xl:text-[22px] 2xl:text-[28px] font-sfPro font-semibold text-[#DA0000] text-center xl:text-left">
                  Andrew Simmons
                  <span className="text-[14px] sm:text-[30px] lg:text-[40px] xl:text-[20px] 2xl:text-[22px] font-sfPro text-[#DA0000] text-center xl:text-left leading-0 sm:leading-[3rem]">
                    [pizza automation guru and owner of Mamma Ramona's Pizzeria]
                  </span>
                </p>
              ) : (
                <div className="xl:ml-[15%] ml-[35%]">
                  <p className="text-[22px] sm:text-[38px] lg:text-[42px] xl:text-[20px] 2xl:text-[21px] font-sfPro font-semibold text-[#DA0000] text-center xl:text-left leading-[26px] sm:leading-[2rem] xl:leading-[1rem]">
                    Andrew Simmons <br />
                    <span className="text-[14px] sm:text-[30px] lg:text-[40px] xl:text-[14px] 2xl:text-[16px] font-sfPro font-light text-[#DA0000] text-center xl:text-left leading-0 sm:leading-[3rem] xl:leading-[1.5rem]">
                      [pizza automation guru and owner of Mamma Ramona's
                      Pizzeria]
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 sm:mt-20 xl:mt-6 flex flex-col xl:flex-row gap-6 xl:gap-8 2xl:gap-20 items-center justify-center">
        <div className="flex gap-5 sm:mb-16 items-center">
          <div className="w-[12%] mr-2 sm:mr-0">
            <img
              src={PizzaSlice}
              alt="PizzaSlice"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            />
          </div>
          <h1 className="w-[88%] text-[18px] sm:text-[40px] lg:text-[50px] xl:text-[20px] 2xl:text-[24px] lg:leading-[55px] xl:leading-[30px] 2xl:leading-[38px] font-sfPro font-medium">
            {" "}
            <span className="text-[#740C00] font-semibold">
              Raise up to $200K
            </span>{" "}
            by selling pizza subscriptions. Upfront capital with 0% interest.
          </h1>
        </div>
        <div className="flex gap-5 sm:mb-16 items-center">
          <div className="w-[12%] mr-2 sm:mr-0">
            <img
              src={PizzaSlice}
              alt="PizzaSlice"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            />
          </div>
          <h1 className="w-[88%] text-[18px] sm:text-[40px] lg:text-[50px] xl:text-[20px] 2xl:text-[24px] lg:leading-[55px] xl:leading-[30px] 2xl:leading-[38px] font-sfPro font-medium">
            <span className="text-[#740C00] font-semibold">
              Increase your loyal customer base.
            </span>{" "}
            Pay back with pizzas and profit from every transaction.
          </h1>
        </div>
        <div className="flex gap-5 sm:mb-16 items-center">
          <div className="w-[12%] mr-2 sm:mr-0">
            <img
              src={PizzaSlice}
              alt="PizzaSlice"
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            />
          </div>
          <h1 className="w-[88%] text-[18px] sm:text-[40px] lg:text-[50px] xl:text-[20px] 2xl:text-[24px] lg:leading-[55px] xl:leading-[30px] 2xl:leading-[38px] font-sfPro font-medium">
            <span className="text-[#740C00] font-semibold">
              Pizza Subscriptions
            </span>{" "}
            bring customers back weekly, while you profit more from
            upsells...much more.
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Index
