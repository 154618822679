import React from "react";

const List = ({ listItems, btnText, text, text2, btnTextRed, handleOpenPopup }) => {

  if (!listItems || listItems.length === 0) {
    return null;
  }

  return (
    <>
      {" "}
      <div className="bg-[#F2EFE2] w-full sm:w-[98%] h-auto rounded-[50px] relative">
        <div className="absolute top-10 sm:top-14 right-8 left-[-10px] xl:left-[-50px] sm:left-[-20px] z-10 ">
          <button className="font-oswald font-semibold bg-[#FBCD33] text-black py-0 px-[20px] sm:px-[65px] text-[30px] xl:text-[45px] rounded-full sm:text-[58px] mx-auto text-center uppercase">
            {btnText}
          </button>
        </div>
        <div className="px-3 sm:px-8 xl:px-7 py-12 pt-28 sm:pt-40">
          <p className="text-[21px] sm:text-[37px] lg:text-[42px] xl:text-[27px] 2xl:text-[32px] font-oswald text-left font-medium text-[#740C00] pl-4">
            {text} <br /> {text2}
          </p>
          {/* <p className="text-[21px] sm:text-[37px] lg:text-[42px] xl:text-[30px] 2xl:text-[32px] font-oswald text-left font-medium text-[#740C00]">
            {text2}
          </p> */}
          <ul className="list-disc px-8 sm:px-14 pt-6 sm:pt-10 lg:pt-6">
            {listItems.length &&
              listItems.map((item, index) => (
                <li
                  key={index}
                  className="text-[18px] sm:text-[30px] lg:text-[35px] xl:text-[20px] 2xl:text-[26px] font-sfPro lg:leading-12 xl:leading-9 sm:mb-2 2xl:mb-4"
                >
                  {item.bold && <span className="font-bold">{item.bold}</span>}{" "}
                  {item.text}
                </li>
              ))}
          </ul>
        </div>
        <div className="absolute bottom-[-30px] right-0 left-0 z-10 flex justify-center">
          <button className="font-sfPro font-semibold bg-[#BE0009] text-white py-2 px-4 rounded-full text-[22px] sm:text-[32px] w-[70%] sm:w-[50%] lg:w-[40%] xl:w-[55%] 2xl:w-[50%] text-center mt-12 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleOpenPopup}>
            {btnTextRed}
          </button>
        </div>
      </div>
    </>
  );
};

export default List;
