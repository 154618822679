export const listItems1  = [
    { bold: "Loyalty.", text: "Regulars become more loyal and spend more frequently" },
    { bold: "Retention.", text: "Your new customers are “stuck with you” for a year [in a positive way]" },
    { bold: "Exclusivity.", text: "You get a “lock” on pizza category with subscriptions" },
    { bold: "Capital.", text: "At zero % interest" },
    { bold: "Sales.", text: "Subscription traffic increases weekly sales with upsells" },
    { bold: "Data.", text: "Capture and keep the most valuable asset you have" }
  ];

  export const listItems2  = [
    { text: "Pizza subscription management platform" },
    {text: "Subscription payment methods" },
    { text: "Branded customer redemption page " },
    { text: "Campaign marketing tools" },
    { text: "Customer data management and analytics" },
    { text: "Redemption codes automation preventing fraud and abuse" },
    { text: "Branded storefront to boost online orders" },
    { text: "Guardrails to prevent excess redemptions" },
    { text: "Upselling tools to increase average check" },
    { text: "Expert team with 24/7 customer support" }
  ];

  export const listItems3  = [
    { text: "New channel to pre-sell pizzas" },
    { text: "Innovative customer acquisition tool" },
    {text: "New revenue stream" },
    { text: "Expand customer reach and awareness" },
    { text: "Raise cash immediately at zero % interest" },
    { text: "Build loyal customer base" },
    { text: "Get customers coming back weekly" },
    { text: "Increase online orders" },
    { text: "Bring customers back to your brick and mortar" }
  ];

  export const listItems4  = [
    { text: "Be prepared to serve more guests" },
    {text: "Have enough products available" },
    { text: "Control product quality and consistency" },
    { text: "Train to upsell [this is the objective, right?]" },
    { text: "Use automation to lower your costs [with the help of PizzaBox]" },
    { text: "Implement safeguards [with our tools] to ensure better service" }
  ];