import React from "react";
// import { faqData } from "./faqDataNew";
import { faqData } from "./faqData";

import Facebook from "../../images/footer/facebook.png";
import Instgram from "../../images/footer/instgram.png";
import Linkedin from "../../images/footer/linkedin.png";

const Index = React.forwardRef(({ id }, ref) => {
  const [showFaqIndex, setShowFaqIndex] = React.useState(null);

  const handleShowFaqDetail = (index) => {
    setShowFaqIndex(showFaqIndex === index ? null : index);
  };
  return (
    <>
      <div className="pt-16 pb-8 sm:pt-20 sm:pb-32 relative">
        <div className="px-3 xl:px-0">
          <p className="text-[24px] sm:text-[50px] xl:text-[40px] font-oswald text-center font-semibold text-[#EB001B]">
            “This is the program every pizzeria needs to embrace!”
          </p>
          <div className="mt-9 xl:ml-[55%] ml-[20%]">
            <p className="font-sfPro text-[22px] sm:text-[35px] font-semibold text-[#000000] text-left">
              Andrew Simmons
            </p>
            <p className="font-sfPro text-[18px] sm:text-[27px] font-light text-[#DA0000] text-left">
              Mamma Ramona’s Pizzeria
            </p>
          </div>
        </div>
        <div id={id} ref={ref} className="scroll-mt-navbar-sm md:scroll-mt-navbar-md lg:scroll-mt-navbar-lg"></div>
        <h2 className="font-oswald font-semibold text-center leading-[3rem] sm:leading-[6rem] xl:leading-[8rem] text-[40px] sm:text-[71px] lg:text-[70px] xl:text-[65px] text-black uppercase mt-16">
          frequently asked questions
        </h2>
        <div className="mt-9 w-full xl:w-[70%] mx-auto">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className="border border-black rounded-[48px] w-full h-auto px-6 py-4 sm:px-12 sm:py-8 xl:py-6 mb-10"
            >
              <h3
                className="text-[21px] sm:text-[35px] xl:text-[32px] 2xl:text-[40px] font-sfPro font-semibold text-[#740C00] cursor-pointer"
                onClick={() => handleShowFaqDetail(index)}
              >
                {showFaqIndex === index ? "-" : "+"} {faq.question}
              </h3>
              {showFaqIndex === index && (
                <div className="faq-answer">
                <div
                  className="text-[16px] sm:text-[20px] lg:text-[25px] xl:text-[20px] 2xl:text-[24px] font-sfPro text-[#000000] pl-1 sm:pl-2 pt-4 pb-4 list-disc ml-3 sm:ml-4"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="absolute bottom-[-18px] sm:bottom-[-40px] right-0 left-0 z-10 flex justify-center gap-8 sm:gap-20">
          <p>
            <a href="https://www.facebook.com/pizzaboxai">
              <img src={Facebook} alt="Facebook" className="w-10 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale" />
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/pizzaboxai/">
              <img src={Instgram} alt="Instgram" className="w-10 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale" />
            </a>
          </p>
          <p>
            <a href="https://www.linkedin.com/company/restogpt/">
              <img src={Linkedin} alt="Linkedin" className="w-10 sm:w-full transition-transform duration-300 ease-in-out hover:bounce-scale" />
            </a>
          </p>
        </div>
      </div>
    </>
  );
});

export default Index;
