export const faqData = [
  {
    question: "What is a PizzaBox AI?",
    answer: `<div>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
               PizzaBox AI is an easy-to-implement and scalable pizza subscription management and billing platform. It offers a new revenue generation model for restaurants, building customer loyalty while securing steady cash flow and increased check sizes via weekly upsells and automation.   
              </p>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
              PizzaBox is powered by RestoGPT AI, leader in AI and automation for restaurants.
              </p>
            </div>`,
  },
  {
    question: "Who is Andrew Simmons?",
    answer: `<div>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
                Andrew Simmons [https://pizzatv.com/andrew/] is a pizza industry
                disruptor and innovative mind behind San Diego-based Mamma
                Ramona's Pizzeria and Pizza Roboto. He is also an advisor
                to PizzaBox pizza subscription platform.
              </p>
            </div>`,
  },
  {
    question: "How does it work?",
    answer: `<div>
              <ul style="list-style-type: disc; margin-left: 20px;">
                <li className="mb-1">Customers pay upfront for a year of weekly pizza.</li>
                <li className="mb-1">You get an upfront cash injection without interest or loans.</li>
                <li className="mb-1">Regulars turn more loyal and spend more every week.</li>
              </ul>
            </div>`,
  },
  {
    question: "How much will it cost?",
    answer: `<div>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
                There are no upfront fees whatsoever.
              </p>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-0">
                We take a small percentage fee for launching your branded pizza subscriptions, managing customer redemptions and providing end-to-end support for the entire year.
              </p>
            </div>`,
  },
  {
    question: "How much capital can I raise?",
    answer: `<div>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
                Depends on your current customer database, social media following and other factors, but we’ll do everything possible and use the most innovative marketing tactics to help you sell up to 1,000 subscriptions and earn you $197,000 [1,000 subscriptions at $197/subscription].
              </p>
            </div>`,
  },
  {
    question: "Can I do this myself?",
    answer: `<div>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4 pb-8 mb-10">
                Yes, you [probably] can, but there are other factors to consider.
              </p>
              <br/>
              <ul style="list-style-type: disc; margin-left: 33px;">
                <li className="mb-1">
                  Subscription Launchpad – customers need to feel comfortable and secure to pay
                </li>
                <li className="mb-1">
                  Technology built specifically for pizza subscriptions and redemptions
                </li>
                <li className="mb-1">
                  Managing your digital storefront for weekly redemptions
                </li>
                <li className="mb-1">
                  Guardrails [or safeguards] to control redemptions during rush hours
                </li>
                <li className="mb-1">
                  Customer registration and account validation
                </li>
                <li className="mb-1">
                  Marketing – this is so important as unprofessional marketing can destroy your efforts and permanently damage your brand’s image
                </li>
                <li className="mb-1">Redemption code management – this requires automation, validation and control</li>
                <li className="mb-1">Scam and fraud protection</li>
                <li className="mb-1">Legal and refund policies</li>
                <li className="mb-1">Customer support</li>
                <li>Payment processing and invoicing</li>
                <li>Customer data protection </li>
                <li>Reminders and upselling tools, plus much more… </li>
              </ul>
            </div>`,
  },
  {
    question: "Why is marketing important?",
    answer: `<div>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
                The objective is to sell as many subscriptions as possible. Marketing is the essential part to create awareness and get the information delivered to your potential customers.
              </p>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
                PizzaBox Subscription Platform provides automated marketing and engagement tools to increase customer awareness and drive traffic, such as:
              </p><br/>
              <ul style="list-style-type: disc; margin-left: 33px;">
                <li className="mb-1">SEO-optimized website and ordering/redemption platform</li>
                <li className="mb-1">Automated customer email and SMS campaigns</li>
                <li className="mb-1">Facebook, Instagram, TikTok posts with targeted CTA</li>
                <li className="mb-1">Facebook, Yelp, Nextdoor Ads</li>
                <li className="mb-1">Facebook retargeting campaigns</li>
                <li className="mb-1">Google keywords and Adsense</li>
                <li className="mb-1">Social media content design database</li>
                <li className="mb-1">PR and local news promo</li>
                <li>Local community targeted posts</li>
                <li>Local influencer network and promo</li>
              </ul>
            </div>`,
  },
  {
    question: "How much can I make with subscriptions?",
    answer: `<div>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
               There are many factors, but it’s possible to sell up to 1,000 pizza subscriptions [we do not recommend more]. Earnings from [1,000] subscriptions can go as high as $197,000 [at $197 per annual subscription]. Additional revenue from upselling can go as high as $520,000/year.
              </p>
              <p className="text-[31px] font-sfPro text-[#000000] pl-10 pt-4">
                We’ve built a calculator [above] so you can play with numbers. Give it a try.
              </p>
            </div>`,
  },
];
