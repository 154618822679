import React from 'react';
import { Modal } from 'antd';

import ComingSoon from "../../images/coming-soon.png";

const Index = ({ open, onClose }) => {
  return (
    <>
      <Modal
        title=""
        centered
        open={open}
        width={500}
        onCancel={onClose}
        footer={null} // Hide the footer
        className="custom-modal" // Add a custom class
        style={{ boxShadow: 'none', backgroundColor: "transparent"}} // Apply styles directly
      >
        <div className='flex justify-center items-center'>
          <img src={ComingSoon} alt="ComingSoon" />
        </div>
      </Modal>
    </>
  );
};

export default Index;
